import React, { useCallback, useContext, useEffect, useState } from "react"
import {
  Col,
  Form,
  Row,
  Typography,
  Input,
  Button,
  Divider,
  Select,
  Empty,
  Spin,
} from "antd"
import "./style.scss"

import { ListProductsContext } from "."

import { AccountContext } from "../../../context/account"
import { getAllProductTag } from "../../../services/user"

export default function SearchTableListProduct({ debounceTimeout = 500 }) {
  const { collections } = useContext(AccountContext)

  const [collectionsMax, setCollectionsMax] = useState()
  const [collectionsMedium, setCollectionsMedium] = useState()
  const [collectionsSmall, setCollectionsSmall] = useState()
  const { setSearchParams, isLoading } = useContext(ListProductsContext)
  const [form] = Form.useForm()

  const onFinish = (values) => {
    setSearchParams(values)
  }

  useEffect(() => {
    setCollectionsMax(collections)
  }, [collections])

  const handleCollectionMax = (value) => {
    form.setFieldsValue({ selectCollectionMedium: undefined })
    form.setFieldsValue({ selectCollectionSmall: undefined })
    setCollectionsSmall([])
    const data = collectionsMax.find((col) => col.id == value)
    setCollectionsMedium(data ? data.children : "")
  }

  const onSecondCityChange = (value) => {
    form.setFieldsValue({ selectCollectionSmall: undefined })
    const data = collectionsMedium.find((col) => col.id == value)
    setCollectionsSmall(data ? data.children : "")
  }

  const [dataOrigin, setDataOrigin] = useState(null)

  const getProductTags = useCallback(async () => {
    const res = await getAllProductTag({ typeTag: "origin" })
    if (res.data) {
      setDataOrigin(res.data?.productTag)
    }
  }, [])

  useEffect(() => {
    if (!dataOrigin) {
      getProductTags()
    }
  }, [])

  return (
    <div className="customFormSearch">
      <Form
        colon={false}
        onFinish={onFinish}
        style={{ backgroundColor: "#FAFAFA", padding: "1rem  2rem 2rem 2rem" }}
        labelCol={{ span: 4 }}
        form={form}
        requiredMark={false}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
      >
        <Row className="mb-3">
          <Typography style={{ color: "#9B9B9B" }} className="font-medium">
            絞り込み条件
          </Typography>
        </Row>
        <Spin spinning={collections?.length == 0}>
          {" "}
          <Row>
            <Col span={12}>
              {collections?.length > 0 && (
                <Form.Item
                  label={
                    <Typography
                      style={{ display: "flex", width: "85px" }}
                      className="font-semibold mt-1"
                    >
                      カテゴリ
                    </Typography>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      gap: 10,
                    }}
                  >
                    <Form.Item
                      name="selectCollectionMax"
                      className=" flex flex-1  [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className="select_search-select w-full"
                        placeholder="大カテゴリ"
                        showSearch
                        allowClear
                        onChange={handleCollectionMax}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsMax &&
                          collectionsMax.map((great) => (
                            <Select.Option value={great.id} key={great.id}>
                              {great.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="selectCollectionMedium"
                      className=" flex flex-1 [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className=" select_search-select"
                        onChange={onSecondCityChange}
                        placeholder="中カテゴリ"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        notFoundContent={
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>データなし</span>}
                          />
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsMedium &&
                          collectionsMedium.map((git) => (
                            <Select.Option value={git.id} key={git.id}>
                              {git.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="selectCollectionSmall"
                      className=" flex flex-1 [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className="select_search-select"
                        placeholder="小カテゴリ"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        notFoundContent={
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>データなし</span>}
                          />
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsSmall &&
                          collectionsSmall.map((small) => (
                            <Select.Option value={small.id} key={small.id}>
                              {small.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                name="origin"
                allowClear
                label={
                  <Typography
                    className="font-semibold"
                    style={{ display: "flex", width: "93px" }}
                  >
                    産地
                  </Typography>
                }
              >
                <Select
                  mode="multiple"
                  allowClear
                  className="fix_antd_remove_selection"
                  placeholder="産地を選択してください"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataOrigin &&
                    dataOrigin.map((great) => (
                      <Select.Option value={great.id} key={great.id}>
                        {great.value}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
        <Divider></Divider>

        <Row>
          <Col span={12}>
            <Form.Item
              name="keyword"
              allowClear
              label={
                <Typography
                  className="font-semibold"
                  style={{ display: "flex" }}
                >
                  キーワード
                </Typography>
              }
            >
              <Input placeholder="キーワードで探す" allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              className="w-36 ml-[10px]"
              type="primary"
              loading={isLoading}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
