import React, { useEffect } from "react"
import Topbar from "../../../../components/organisms/topbar"
import CustomLayout from "../../../../Layouts/Layout"
import queryString from "query-string"

import "../../styles.scss"
import { navigate } from "gatsby"
import SettingForProduct from "../../../../domain/superSalesCommissionSettingCompany/SettingForProduct"

const index = ({ location }) => {
  const query = queryString.parse(location.search)

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (!query?.storeId) {
      navigate(
        "/super-sales-commission-setting-company/?pageCurrent=1&pageSize=10"
      )
    }
  }, [location])
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout
        selectedPage={<SettingForProduct storeId={query?.storeId} />}
      />
    </div>
  )
}

export default index
